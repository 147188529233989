import React, { useState } from "react";
import styled from "styled-components";
import { Block, Button } from "../components/ui";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { validate } from "react-email-validator";
import axios from "axios";

import keynote from "../assets/WeTechFood_Deck_En.pdf";

import PhoneInput from 'react-phone-input-2'
//import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'

const Wrapper = styled(Block)`
  background: #fff;
  min-height: 400px;
  margin-top: 73px;
  margin-bottom: 10px;
`;

const Card = styled.div`
  //max-height: 550px;
  min-height: 360px;
  width: 90vw;
  max-width: 1240px;
  background: #1b2033;
  margin: 0 auto;
  border-radius: 18px;
  padding: 22px min(70px, 8vw);
`;

const PreTitle = styled.h3`
  color: #ffbf69;
  margin-bottom: 40px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  white-space: pre-wrap;
  color: #ffffff;
  text-align: center;
  margin-top: 0px;
`;

const DownloadButton = styled(Button)`
  height:45px;
  margin-top:50px;
  margin-bottom:50px;
  margin-left:auto;
  margin-right:auto;
  display:block;
`;

const Form = styled.div`
  margin-top:50px;
`;


const InputRow = styled.div`
  display: flex;
  height: 48px;
  border-radius: 24px;
  /* overflow: hidden; */
  background: #fff;
  max-width: 420px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;

  & input {
    flex: 1;
    /* margin: -2px; */
    border: none;
    padding: 0 5px 0 30px;
    min-width: 50px;
    border-radius: 24px;
    max-height: 100%;

    &:focus-visible,
    &:focus {
      outline: 0px solid transparent;
    }
  }

  & button {
    min-width: 150px;
    border-radius: 28px;
    background: #ff9f1c;
    color: #fff;
    border: none;
  }

  ${({ error }) =>
    error !== "" &&
    `    
    box-shadow : 0 0 2px 2px #ff5151;

    &:after {
      content: "${error}";
      font-size: 84%;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 53px);
      color: #ff5151;
    }

  `}
`;

const Loader = styled.div`
  margin: 0 auto;
  font-size: 2.2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: rotateAnimation 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  @-webkit-keyframes rotateAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotateAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const DetailedInfoForm = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("Unknown");
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const downloadKeynote = () => {
    const filename = "WeTechFood-Deck-2023.pdf";
    const dataUrl = keynote; // imported at the top of the document

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const submitHandler = async (e) => {
    let nameValidated = false;
    let companyValidated = false;
    let emailValidated = false;

    if(name !==""){
      setNameError("");
      nameValidated = true;
    }
    if(company !== ""){
      setCompanyError("");
      companyValidated = true;
      
    }
    if(validate(email)){
      setEmailError("");
      emailValidated = true;
    }
    
    if(nameValidated && companyValidated && emailValidated) {
      setIsLoading(true);
      try {
        await axios.post("/api/download-keynote", {
          name,
          company,
          email,
          phoneNumber,
        });
        downloadKeynote();
        setThankYouMessage("Thanks! Your download should start in a moment.");
      } catch (e) {
        console.log(e);
        setThankYouMessage(
          "Sorry! There's been a problem. Please try again later."
        );
      }

      setIsLoading(false);
    } else{
      if (!nameValidated) {
        setNameError("Please submit your full name");
      } 
      if (!companyValidated) {
        setCompanyError("Please submit your company name");
      }
      if (email === "") {
        setEmailError("Please submit your email");
      } 
      else {
        setEmailError("Please submit a valid email");
      }
    }
  };

  return (
    <Wrapper id="info">
      <Card>
        {thankYouMessage === "" ? (
          <Form>
            <Title>{t("call_to_action_email.title")}</Title>
            <PreTitle>{t("call_to_action_email.pre_title")}</PreTitle>
            <InputRow error={nameError}>
              <input
                type="text"
                placeholder={t("call_to_action_email.name_placeholder")}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && submitHandler()}
              />
            </InputRow>

            <InputRow error={companyError}>
              <input
                type="text"
                placeholder={t("call_to_action_email.company_placeholder")}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && submitHandler()}
              />
            </InputRow>

            <InputRow error={emailError}>
              <input
                type="email"
                placeholder={t("call_to_action_email.email_placeholder")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => e.key === "Enter" && submitHandler()}
              />
            </InputRow>
            
            <InputRow error={""}>
              <PhoneInput
                style = {{marginLeft: "17px"}} 
                inputStyle = {{border: "none", boxShadow: "none", maxWidth: "87%"}}
                country="es"
                onChange={(phone) => setPhoneNumber(phone)}
                onKeyDown={(e) => e.key === "Enter" && submitHandler()}
              />
            </InputRow>

            <DownloadButton primary onClick={submitHandler} disabled={isLoading}>
                {isLoading ? (
                  <Loader />
                ) : (
                  t("call_to_action_email.button_download")
                )}
            </DownloadButton>
          </Form>
        ) : (
          <PreTitle
            style={{
              marginTop: "125px",
              fontWeight: 400,
              textTransform: "none",
            }}
          >
            {thankYouMessage}
          </PreTitle>
        )}
      </Card>
    </Wrapper>
  );
};

export default DetailedInfoForm;
